<template>
  <!-- <v-card
    class="mx-auto mt-4" 
      max-width="350"
  >
    <v-img
      src="../assets/img/cisco_network_academy_app.jpg"
      height="200px"
    ></v-img>

    <v-card-title class="titulo pb-0">
      TODOS NUESTROS CLIENTES RECIBEN CAPACITACIONES GRATUITAS DE CISCO
    </v-card-title>
  </v-card> -->
    <router-link :to="{name: 'cursocisco'}">
  <v-card
    class="mx-auto mt-4 mb-4"
    max-width="344"
  >
    <v-img
      src="../assets/img/cisco_network_academy_app.jpg"
      height="200px"
    ></v-img>

    <v-card-title>
     CISCO
    </v-card-title>

    <v-card-subtitle>
      Cisco Networking Academy
    </v-card-subtitle>

      
    
  </v-card>
     </router-link>
</template>
<script>
export default {
    data: () =>({
    }),
}
</script>

<style>
.titulo{
    color: #616161;
}
</style>